import React, { useEffect } from 'react';
import * as StackBlur from 'stackblur-canvas';
import socialmedia_image from '../assets/images/manage-2748752_1920.jpg';
import '../assets/styles/index.scss';
import '../assets/styles/main.scss';

/**
 * Stitchz.net index image 1
 * @returns {HTMLElement} html of the index page image 1
 */
export default function IndexImage1() {
    // load home page background effects
    useEffect(() => {
        let isMounted = true;

        /**
         * Asynchronis function laoding page events
         */
        async function onLoad() {
            try {
                window.onload = renderBackground;
                document.onscroll = resizeHeader;

                // running this only once
                const header = document.getElementsByClassName('navbar')[0];
                if (header && header.classList.contains('static')) {
                    header.classList.remove('static');
                }
            } catch (e) {
                // if (e !== 'No current user') {
                //     onError(e);
                // }
                console.log(e);
            }
        }

        // polling if page is ready, then run scripts - https://davidwalsh.name/document-readystate
        let interval = setInterval(function () {
            console.log('interval is running...');
            if (document.readyState === 'complete') {
                clearInterval(interval);
                renderBackground();
                resizeHeader();
                console.log('Page loaded...');
            }
        }, 100);

        if (isMounted) {
            onLoad()
                .catch(err => {
                    console.error(err);
                });
        }

        // clean up async calls
        return () => { isMounted = false; };
    }, []);

    /**
     * Initialize the blurred background
     * @returns {void}
     */
    const renderBackground = () => {
        console.log('renderBackground initialized...');
        const c = document.getElementById('canvas');
        // console.log(c);
        StackBlur.image('background-logo', 'canvas', 100, true);
        if (c) {
            c.removeAttribute('style');
        }
    };

    /**
     * Reset the header navigation
     * @returns {void}
     */
    const resizeHeader = () => {
        var distanceY = window.pageYOffset || document.documentElement.scrollTop,
            shrinkOn = 30,
            header = document.getElementsByClassName('navbar')[0];

        if (distanceY > shrinkOn) {
            if (header) {
                header.classList.add('smaller');
            }
        } else {
            if (header) {
                if (header.classList.contains('smaller')) {
                    header.classList.remove('smaller');
                }
            }
        }
    };

    return (
        <img src={socialmedia_image} className="img-fluid" alt="Take Control of Your Content" />
    );
}
